import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuDivider = lazy(
  () => import("./menu-divider-Dtf6pHvh.js").then((module) => ({
    default: module.MenuDivider
  }))
);
function MenuDivider({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuDivider, { ...props });
}
const useMenuDividerContent = () => {
  return useMemo(
    () => ({
      menuDivider: {
        component: MenuDivider
      }
    }),
    []
  );
};
export {
  LazyMenuDivider as MenuDivider,
  useMenuDividerContent
};
